import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BottomBar from './BottomBar';
import ReactPlayer from "react-player";
import logo from './../images/realeyesLogo.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const linkPaddingPixels = '12px';

const Navbar = () => {
  return (
    <div style={{
      position: 'fixed',
      top: '0px',
      width: '100%',
      height: 80,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#20233A',
      fontSize: 15,
    }}>
      <div style={{
        height: 80,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#20233A',
        fontSize: 15,
      }}>
        <img src={logo} alt='Logo Image' style={{ width: '90px', padding: '20px' }} />
        <h1 style={{ color: '#FFFFFF', paddingLeft: '30px' }}>RealEyes</h1>
      </div>
    </div>
  );
};

const Login = ({ handleLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Sign in with Firebase Authentication
      await firebase.auth().signInWithEmailAndPassword(email, password);
      // Check if the user is authenticated
      const user = firebase.auth().currentUser;
      if (user) {
        // Perform necessary actions upon successful login
        handleLogin(); // Call the handleLogin function to manage authentication state
        setShowErrorMessage(false);
        // Redirect or perform any actions needed upon successful login
        // Example: history.push('/dashboard');
      } else {
        setShowErrorMessage(true);
        console.error('Login failed: Authentication failed');
      }
    } catch (error) {
      setShowErrorMessage(true);
      console.error('Login failed:', error);
    }
  };

  return (
    <div>
      <Navbar />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 120 }}>
        <img
          src={logo}
          alt="Logo"
          width='300px'
        />
      </div>
      <br />

      <h4 style={{ textAlign: 'center' }}>Please enter the credentials to access RealEyes content:</h4>
      <br />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <form onSubmit={handleSubmit}>
          <input
            type='text'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: '15px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '300px' }}
          />
          <br />
          <input
            type='password'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginBottom: '15px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '300px' }}
          />
          <br />
          <button
            type='submit'
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              color: '#FFFFFF',
              backgroundColor: '#20233A',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
              display: 'block',
              margin: '0 auto',
            }}>Login</button>


          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <a
              href='mailto:matthew.whallett@doctors.org.uk?subject=RealEyes%20access%20request&body=Dear%20RealEyes%20team,%0D%0A%0D%0APlease%20could%20I%20request%20access%20to%20the%20RealEyes%20content.%0D%0AI%20intend%20to%20use%20RealEyes%20as%20a%20provider%20/%20student%20(delete%20as%20appropriate)%0D%0A%0D%0A[insert%20your%20name,%20organisation%20and%20role%20here]'
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                color: '#FFFFFF',
                backgroundColor: '#20233A',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                textDecoration: 'none',
              }}
            >
              Request Access via Email
            </a>
          </div>
        </form>
      </div>
      {showErrorMessage && (
        <p style={{ textAlign: 'center', color: 'red' }}>Incorrect email or password</p>
      )}

      <br />
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <p>
          <b>RealEyes</b> is a course designed to teach doctors about the importance of Professional and Ethical Responsibilities using virtual reality technology.
        </p>
        <p>RealEyes has been developed as a collaboration between the Dudley Group NHS Foundation Trust and NHS England.</p>
        <p>If you are interested in delivering this course, accessing the complete content on this website, or contributing to RealEyes' development, please contact <a href='mailto:matthew.whallett@doctors.org.uk'>Dr Matthew Whallett</a>.</p>
      </div>
      <div>
        <br />
        <div>
          <ReactPlayer
            url='https://vimeo.com/803030354/7084a19d53'
            controls='true'
            width='100%'
          />
        </div>
      </div>
      <br />
      <BottomBar />
    </div>
  );
};

export default Login;
