import React from 'react';

const BottomBar = () => {
    //const [nav, setNav] = useState(false);
    //const handleClick = () => setNav(!nav);

    return (
        <div style={{
            bottom: '0px',
            width: '100%',
            height: 120,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#20233A',
            fontSize: 15,
        }}>
            <div style={{
                height: 80,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#20233A',
                fontSize: 15,
            }}>
                <h1 style={{ color: '#FFFFFF', paddingLeft: '30px' }}>RealEyes</h1>
                <div style={{ paddingLeft: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <p style={{ color: '#FFFFFF', fontSize: 12, textAlign: 'left' }}>Incorporating virtual reality into healthcare education to teach professional values and behaviours</p>
                    <p style={{ color: '#FFFFFF', fontSize: 12, textAlign: 'left' }}>© 2023. The Dudley Group NHS Foundation Trust. All rights reserved.</p>
                    </div>
            </div>
            <div>
            <p style={{ color: '#FFFFFF', paddingLeft: '30px', fontSize: 12, textAlign: 'right', paddingRight: '10px' }}>A collaboration between</p>
            <p style={{ color: '#FFFFFF', paddingLeft: '30px', fontSize: 12, textAlign: 'right', paddingRight: '10px' }}>NHS England</p>
            <p style={{ color: '#FFFFFF', paddingLeft: '30px', fontSize: 12, textAlign: 'right', paddingRight: '10px' }}>The Dudley Group NHS Foundation Trust </p>
            </div>
        </div>
    );
};

export default BottomBar;
