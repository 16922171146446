import React from 'react';
import ReactPlayer from "react-player";
import Table from 'react-bootstrap/Table';
import logo from './../images/realeyesLogo.png';

import ApplyingEthicalReasoningToNewSituations from './../miniPresentations/Applying ethical reasoning to new situations.pdf'
import MaintainingDignity from './../miniPresentations/Maintaining dignity.pdf'
import PersonCentredCare from './../miniPresentations/Person-centred care.pdf'
import RecognisingPersonalLimits from './../miniPresentations/Recognising personal limits.pdf'
import Demonstration from './../miniPresentations/Demonstration.pdf'

const padding = '10%'

const METCall = ({demo = false}) => {
  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
      <br />
      <h1>MET Call Scenario</h1>
      <p>During this scenario, we will address the following learning objectives:</p>
      <ul>
        <li>Applying Ethical Reasoning To New Situations</li>
        <li>Maintaining Dignity</li>
        <li>Person Centred Care</li>
        <li>Recognising Personal Limits</li>
      </ul>
      <br />
      <h4>Viewpoint 1 - Doctor</h4>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactPlayer url={demo ? 'https://vimeo.com/803030354/7084a19d53' : 'https://vimeo.com/845258192/2370379b26'} controls='true'/>
      </div>
      <h4>Viewpoint 2 - Relative</h4>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactPlayer url={demo ? 'https://vimeo.com/803030354/7084a19d53' : 'https://vimeo.com/845232430/71c7e7d330'} controls='true'/>
      </div>
      <br />
      <h2>Mini-presentations</h2>
      <br />
      <ul>
        <li><a href={demo ? Demonstration : ApplyingEthicalReasoningToNewSituations} target='self'>Applying Ethical Reasoning To New Situations</a></li>
        <li><a href={demo ? Demonstration : MaintainingDignity} target='self'>Maintaining Dignity</a></li>
        <li><a href={demo ? Demonstration : PersonCentredCare} target='self'>Person Centred Care</a></li>
        <li><a href={demo ? Demonstration : RecognisingPersonalLimits} target='self'>Recognising Personal Limits</a></li>
      </ul>
      <br />
      <h2>Acting credits</h2>
      <br />
      <li>Phil Franklin</li>
      <li>Olivia Wilde</li>
      <li>Adam Gittins</li>
      <li>Priya Parekh</li>
      <li>Manpreet Badh</li>
      <li>Ruth Sewell</li>
    </div>
  );
};

export default METCall;