import React from 'react';
import ReactPlayer from "react-player";
import logo from './../images/realeyesLogo.png';

const Home = ({admin}) => {
  return (
    <div style={{ width: '100%', paddingTop: '160px', paddingLeft: '10px', paddingBottom: '80px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={logo}
          alt="Logo"
          width='300px'
        />
      </div>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <p>
          <b>RealEyes</b> is a course designed to teach doctors about the importance of Professional and Ethical Responsibilities using virtual reality technology.
        </p>
        <p>RealEyes has been developed as a collaboration between the Dudley Group NHS Foundation Trust and NHS England.</p>
      </div>
      {admin &&
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <a
            href='/Admin'
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              color: '#FFFFFF',
              backgroundColor: '#20233A',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
              textDecoration: 'none',
            }}
          >
            Admin page
          </a>
        </div>
      }
      <div>
        <br />
        <div>
          <ReactPlayer
            url='https://vimeo.com/803030354/7084a19d53'
            controls='true'
            width='100%'
          />
        </div>
      </div>
    </div>
  );
};

export default Home;