import React from 'react';
import ReactPlayer from "react-player";
import Table from 'react-bootstrap/Table';
import logo from './../images/realeyesLogo.png';
import RealEyesPoster from './../images/RealEyesPoster.png'

const padding = '10%'

const ProjectReach = () => {
  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
      <br />
      <h1>Project Reach</h1>
      <br />
      <h4>APMEC 2023</h4>
      <br />
      <p>On 22<sup>nd</sup> May 2023, we gave a <a target='self' href='https://medicine.nus.edu.sg/cenmed/apmec2023/documents/APMEC_Conference%20Handbook.pdf'>short communications poster presentation</a> on RealEyes at <a target='self' href='https://medicine.nus.edu.sg/cenmed/apmec2023/'>APMEC 2023</a>.</p>
      <p>Watch the presentation <a href='https://vimeo.com/829393062/cad1e258ec?share=copy' target='self'>here</a>.</p>
      <br />
      <h6>Poster</h6>
      <img src={RealEyesPoster} alt="RealEyes Poster" style={{ maxWidth: '100%', height: 'auto' }} />
          <br />
    </div>
  );
};

export default ProjectReach;