import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Home from './components/Home';
import LearningObjectives from './components/LearningObjectives'
import IntroductionToReflection from './components/IntroductionToReflection'
import SessionStructure from './components/SessionStructure'
import Stroke from './components/Stroke';
import ClinicalError from './components/ClinicalError';
import METCall from './components/METCall';
import AggressivePatient from './components/AggressivePatient';
import NextSteps from './components/NextSteps';
import Contact from './components/Contact';
import ProjectReach from './components/ProjectReach';
import Login from './components/Login';
import Admin from './components/Admin';
import AccessDenied from './components/AccessDenied';
import InformationForProviders from './components/InformationForProviders';
import 'bootstrap/dist/css/bootstrap.min.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import 'firebase/compat/firestore';

import Navbar from './components/NavBar';
import BottomBar from './components/BottomBar';

let app
let auth

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [appInitialized, setAppInitialized] = useState(false);
  const [accessLevel, setAccessLevel] = useState(1)

  const navigate = useNavigate();

  useEffect(() => {
    async function initializeApp() {
        try {
            const config = {
              apiKey: "AIzaSyBJ28hA2yu7FhymTaj4cc2rMSIRhn8BLgk",
              authDomain: "realeyes-25f89.firebaseapp.com",
              projectId: "realeyes-25f89",
              storageBucket: "realeyes-25f89.appspot.com",
              messagingSenderId: "721250290817",
              appId: "1:721250290817:web:f9aa50bb03bdf98c12d180",
              measurementId: "G-8031SB7T7K"
            }
            app = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
        auth = getAuth(app);
        setAppInitialized(true);

        if (auth) {
          onAuthStateChanged(auth, async (user) => {
            if (user) {
              //setLoggedIn(true);

              // Fetch the authenticated user's email
              const userEmail = user.email;

              // Access Firestore
              const firestore = firebase.firestore(app);

              try {
                // Query Firestore for the user with the authenticated email
                const userDoc = await firestore.collection('Users').where('email', '==', userEmail).get();

                // Check if a document exists for the user
                if (!userDoc.empty) {
                  const userData = userDoc.docs[0].data();

                  // Access the user's accessLevel
                  setAccessLevel(userData.accessLevel);
                }
              } catch (error) {
                console.error('Error fetching user data:', error);
              }
            } else {
              setLoggedIn(false);
            }
          });
        }
      } catch (error) {
        console.error('Error initializing app:', error);
        setAppInitialized(true);
      }
    }

    if (!appInitialized) {
      initializeApp();
    }
  }, [appInitialized]);

  const handleLogin = () => {
    if (accessLevel === 1) {
      //localStorage.setItem('loginTime', new Date().getTime());
      localStorage.setItem('accessLevel', 1)
      setLoggedIn(true);
    } else if (accessLevel === 2) {
      //localStorage.setItem('loginTime', new Date().getTime());
      localStorage.setItem('accessLevel', 2)
      setLoggedIn(true);
    } else if (accessLevel === 3) {
      //localStorage.setItem('loginTime', new Date().getTime());
      localStorage.setItem('accessLevel', 3)
      setLoggedIn(true);
    } else if (accessLevel === 4) {
      //localStorage.setItem('loginTime', new Date().getTime());
      localStorage.setItem('accessLevel', 4)
      setLoggedIn(true);
    } else if (accessLevel === 0) {
      //localStorage.setItem('loginTime', new Date().getTime());
      localStorage.setItem('accessLevel', 0)
      setLoggedIn(true);
    }
  }

  const handleLogout = async () => {
    //localStorage.removeItem('loginTime');
    localStorage.removeItem('accessLevel');
    try {
      await auth.signOut(); // Sign out the user
      setLoggedIn(false);
      setAccessLevel(1);
      navigate('/login'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }

  if (!appInitialized) {
    return (
        <div>Loading...</div>
    )
}

  if (loggedIn && localStorage.getItem('accessLevel') === '1') {
    return (
      <>
        <Navbar handleLogout={handleLogout} accessLevel={1}/>
        <Routes>
          <Route path='/' element={<Home admin={false}/>} />
          <Route path='/LearningObjectives' element={<LearningObjectives />} />
          <Route path='/IntroductionToReflection' element={<IntroductionToReflection />} />
          <Route path='/SessionStructure' element={<SessionStructure />} />
          <Route path='/Stroke' element={<AccessDenied />} />
          <Route path='/ClinicalError' element={<AccessDenied />} />
          <Route path='/METCall' element={<AccessDenied />} />
          <Route path='/AggressivePatient' element={<AccessDenied />} />
          <Route path='/NextSteps' element={<NextSteps />} />
          <Route path='/ProjectReach' element={<ProjectReach />} />
          <Route path='/Contact' element={<Contact />} />
        </Routes>
        <BottomBar />
      </>)
  } else if (loggedIn && localStorage.getItem('accessLevel') === '2') {
    return (
      <>
        <Navbar handleLogout={handleLogout} accessLevel={2}/>
        <Routes>
          <Route path='/' element={<Home admin={false}/>} />
          <Route path='/LearningObjectives' element={<LearningObjectives />} />
          <Route path='/IntroductionToReflection' element={<IntroductionToReflection />} />
          <Route path='/SessionStructure' element={<SessionStructure />} />
          <Route path='/Stroke' element={<Stroke />} />
          <Route path='/ClinicalError' element={<ClinicalError />} />
          <Route path='/METCall' element={<METCall />} />
          <Route path='/AggressivePatient' element={<AggressivePatient />} />
          <Route path='/NextSteps' element={<NextSteps />} />
          <Route path='/ProjectReach' element={<ProjectReach />} />
          <Route path='/Contact' element={<Contact />} />
        </Routes>
        <BottomBar />
      </>)
  } else if (loggedIn && localStorage.getItem('accessLevel') === '3') {
    return (
      <>
      <Navbar handleLogout={handleLogout} accessLevel={3}/>
      <Routes>
        <Route path='/' element={<Home admin={false}/>} />
        <Route path='/LearningObjectives' element={<LearningObjectives />} />
        <Route path='/IntroductionToReflection' element={<IntroductionToReflection />} />
        <Route path='/SessionStructure' element={<SessionStructure />} />
        <Route path='/Stroke' element={<Stroke />} />
        <Route path='/ClinicalError' element={<ClinicalError />} />
        <Route path='/METCall' element={<METCall />} />
        <Route path='/AggressivePatient' element={<AggressivePatient />} />
        <Route path='/NextSteps' element={<NextSteps />} />
        <Route path='/ProjectReach' element={<ProjectReach />} />
        <Route path='/InformationForProviders' element={<InformationForProviders />} />
        <Route path='/Contact' element={<Contact />} />
      </Routes>
      <BottomBar />
    </>
    )
  } else if (loggedIn && localStorage.getItem('accessLevel') === '0') {
    return (
      <>
      <Navbar handleLogout={handleLogout} accessLevel={0}/>
      <Routes>
        <Route path='/' element={<Home admin={false}/>} />
        <Route path='/LearningObjectives' element={<LearningObjectives />} />
        <Route path='/IntroductionToReflection' element={<IntroductionToReflection />} />
        <Route path='/SessionStructure' element={<SessionStructure />} />
        <Route path='/Stroke' element={<Stroke demo={true} />} />
        <Route path='/ClinicalError' element={<ClinicalError demo={true} />} />
        <Route path='/METCall' element={<METCall demo={true} />} />
        <Route path='/AggressivePatient' element={<AggressivePatient demo={true} />} />
        <Route path='/NextSteps' element={<NextSteps />} />
        <Route path='/ProjectReach' element={<ProjectReach />} />
        <Route path='/InformationForProviders' element={<InformationForProviders />} />
        <Route path='/Contact' element={<Contact />} />
      </Routes>
      <BottomBar />
    </>
    )
  } else if (loggedIn && localStorage.getItem('accessLevel') === '4') {
    return (
      <>
      <Navbar handleLogout={handleLogout} accessLevel={4}/>
      <Routes>
        <Route path='/' element={<Home admin={true}/>} />
        <Route path='/LearningObjectives' element={<LearningObjectives />} />
        <Route path='/IntroductionToReflection' element={<IntroductionToReflection />} />
        <Route path='/SessionStructure' element={<SessionStructure />} />
        <Route path='/Stroke' element={<Stroke />} />
        <Route path='/ClinicalError' element={<ClinicalError />} />
        <Route path='/METCall' element={<METCall />} />
        <Route path='/AggressivePatient' element={<AggressivePatient />} />
        <Route path='/NextSteps' element={<NextSteps />} />
        <Route path='/ProjectReach' element={<ProjectReach />} />
        <Route path='/InformationForProviders' element={<InformationForProviders />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Admin' element={<Admin />} />
      </Routes>
      <BottomBar />
    </>
    )
  }
  else {
    return (
      <Login handleLogin={handleLogin} />
    )
  }
}

export default App;
