import React from 'react';
import Debrief from './../InformationForProviders/Debrief.pdf';
import RealEyesSOP from './../InformationForProviders/RealEyesSOP.pdf';
import filePowerpointSolid from './../images/file-powerpoint-solid.svg';
import AggressivePatientCertificate from './../InformationForProviders/AggressivePatientCertificate.pptx'
import ClinicalErrorCertificate from './../InformationForProviders/ClinicalErrorCertificate.pptx'
import METCallCertificate from './../InformationForProviders/METCallCertificate.pptx'
import StrokeCertificate from './../InformationForProviders/StrokeCertificate.pptx'

const padding = '10%';

const InformationForProviders = () => {
  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
      <br />
      <h1>Information for Providers</h1>
      <br />
      <p>Please use the information on this page to guide you on how to deliver your RealEyes session, and download course certificates for your students.</p>
      <br />
      <h4>Standard Operating Procedure</h4>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <object data={RealEyesSOP} type="application/pdf" width="75%" height="800px">
        </object>
      </div>
      <br />
      <h4>How to debrief a RealEyes session</h4>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <object data={Debrief} type="application/pdf" width="75%" height="800px">
        </object>
      </div>
      <br />
      <h4>Session certificates</h4>
      <br />
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <ul>
          <li>
            <a href={AggressivePatientCertificate} download>
              <img src={filePowerpointSolid} alt="PowerPoint Icon" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
              Aggressive patient session certificate
            </a>
          </li>
          <li>
            <a href={ClinicalErrorCertificate} download>
              <img src={filePowerpointSolid} alt="PowerPoint Icon" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
              Clinical error session certificate
            </a>
          </li>
          <li>
            <a href={METCallCertificate} download>
              <img src={filePowerpointSolid} alt="PowerPoint Icon" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
              MET call session certificate
            </a>
          </li>
          <li>
            <a href={StrokeCertificate} download>
              <img src={filePowerpointSolid} alt="PowerPoint Icon" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
              Stroke session certificate
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InformationForProviders;
