import React from 'react';
import ReactPlayer from "react-player";
import Table from 'react-bootstrap/Table';
import logo from './../images/realeyesLogo.png';

import ImpactOfPersonalValuesBeliefsAndBiases from './../miniPresentations/Impact of personal values, beliefs and biases.pdf'
import MeetingFundamentalNeedsOfPatients from './../miniPresentations/Meeting fundamental needs of patients.pdf'
import Integrity from './../miniPresentations/Integrity.pdf'
import RisksPosedToPatientsByDoctorsHealth from './../miniPresentations/Risks posed to patients by doctors health.pdf'
import Demonstration from './../miniPresentations/Demonstration.pdf'

const padding = '10%'

const AggressivePatient = ({demo = false}) => {
  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
            <br />
      <h1>Aggressive Patient Scenario</h1>
      <p>During this scenario, we will address the following learning objectives:</p>
      <ul>
        <li>Impact of personal values, beliefs and biases</li>
        <li>Meeting fundamental needs of patients</li>
        <li>Risks posed to patients by doctors health</li>
        <li>Honesty and integrity</li>
      </ul>
      <br />
      <h4>Viewpoint 1 - Doctor</h4>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactPlayer url={demo ? 'https://vimeo.com/803030354/7084a19d53' : 'https://vimeo.com/836154530/4826db2f61'} controls='true'/>
      </div>
      <h4>Viewpoint 2 - Nurse</h4>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactPlayer url={demo ? 'https://vimeo.com/803030354/7084a19d53' : 'https://vimeo.com/836157409/fde415818a'} controls='true'/>
      </div>
      <br />
      <h2>Mini-presentations</h2>
      <br />
      <ul>
        <li><a href={demo ? Demonstration : ImpactOfPersonalValuesBeliefsAndBiases} target='self'>Impact of personal values, beliefs and biases</a></li>
        <li><a href={demo ? Demonstration : MeetingFundamentalNeedsOfPatients} target='self'>Meeting fundamental needs of patients</a></li>
        <li><a href={demo ? Demonstration : RisksPosedToPatientsByDoctorsHealth} target='self'>Risks posed to patients by doctors health</a></li>
        <li><a href={demo ? Demonstration : Integrity} target='self'>Integrity</a></li>
      </ul>
      <br />
      <h2>Acting credits</h2>
      <br />
      <li>Leslie, FY1 doctor (viewpoint 1) - Jenny Novy</li>
      <li>Oliver, nurse (viewpoint 2) - Joshua Tulley</li>
      <li>Sheryl, nursing assistant - Courtnie Brown</li>
      <li>Sean Bean, patient - Pete Goldsmith</li>
      <li>Andrew Bean, relative - Andrew Wright (voice only)</li>
      <li>Andy McKellen, medical registrar - Ed Shovelton (voice only)</li>
      </div>
    );
};

export default AggressivePatient;