import React from 'react';
import ReactPlayer from "react-player";
import logo from './../images/realeyesLogo.png';

import ApproachToCapacityAssessment from './../miniPresentations/Approach to capacity assessment.pdf'
import Handover from './../miniPresentations/Handover.pdf'
import NoteKeeping from './../miniPresentations/Note Keeping.pdf'
import RaisingConcerns from './../miniPresentations/Raising concerns.pdf'
import RecognisingPersonalLimits from './../miniPresentations/Recognising personal limits.pdf'
import Demonstration from './../miniPresentations/Demonstration.pdf'
import { Note } from '@mui/icons-material';

const padding = '10%'

const Stroke = ({demo = false}) => {
  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
      <br />
      <h1>Stroke scenario</h1>
      <p>During this scenario, we will address the following learning objectives:</p>
      <ul>
        <li>Decision making, capacity and informed consent</li>
        <li>Recognising personal limits</li>
        <li>Raising concerns</li>
      </ul>
      <p>Furthermore, this scenario also addresses the following ethical issues which are not specifically mandated in <i>Outcomes for Graduates</i>:</p>
      <ul>
        <li>Handover</li>
        <li>Notekeeping</li>
      </ul>
      <br />
      <h4>Viewpoint 1 - Patient</h4>
      <p>For the patient's viewpoint in this scenario, you will need to place the hemianopioniser inside your headset. After completion of this video, you can take the hemianopioniser out of the headset and watch the doctor's viewpoint normally</p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactPlayer url={demo ? 'https://vimeo.com/803030354/7084a19d53' : 'https://vimeo.com/836164913/92d16d78ae'} controls='true'/>
      </div>
      <h4>Viewpoint 2 - Doctor</h4>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactPlayer url={demo ? 'https://vimeo.com/803030354/7084a19d53' : 'https://vimeo.com/836166219/d3889e76a4'} controls='true'/>
      </div>
      <br />
      <h2>Mini-presentations</h2>
      <br />
      <ul>
        <li><a href={demo ? Demonstration : ApproachToCapacityAssessment} target='self'>Approach to capacity assessment</a></li>
        <li><a href={demo ? Demonstration : Handover} target='self'>Handover</a></li>
        <li><a href={demo ? Demonstration : NoteKeeping} target='self'>Note keeping</a></li>
        <li><a href={demo ? Demonstration : RaisingConcerns} target='self'>Raising concerns</a></li>
        <li><a href={demo ? Demonstration : RecognisingPersonalLimits} target='self'>Recognising personal limiits</a></li>
      </ul>
      <br />
      <h2>Acting credits</h2>
      <br />
      <li>Manpreet, FY1 doctor (viewpoint 1) - Manpreet Badh</li>
      <li>Dr Parekh, stroke registrar - Priya Parekh</li>
      <li>Laura, nurse - Laura James</li>
      <li>Summer, medical student - Summer Hiscox</li>
      <li>Lois, patient (viewpoint 2) - Lois Parkes</li>
    </div>
  );
};

export default Stroke;
