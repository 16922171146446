import React from 'react';
import ReactPlayer from "react-player";
import Table from 'react-bootstrap/Table';
import logo from './../images/realeyesLogo.png';

import Integrity from './../miniPresentations/Integrity.pdf'
import DutyOfCandour from './../miniPresentations/Duty of candour.pdf'
import TimeManagement from './../miniPresentations/Time Management.pdf'

const padding = '10%'

const AccessDenied = () => {
    return (
        <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
            <br />
            <h1>Access denied</h1>
            <p>In order to protect the content so that students do not see material in advance of the sessions, the scenario pages are protected.</p>
            <p>If you know the access password to this content, please log out and log in again with the correct password.</p>
            <p>If you require access to this page but do not have the password, please contact <a href='mailto:matthew.whallett@doctors.org.uk'>Dr Matthew Whallett</a> for the correct login information.</p>
        </div>
    );
};

export default AccessDenied;