import React, { useState, useEffect } from 'react';
import Logo from './../images/realeyesLogo.png';
import { Link } from 'react-router-dom';

const linkPaddingPixels = '12px';

const Navbar = ({ handleLogout, accessLevel }) => {

  const [providerAccess, setProviderAccess] = useState(false) 
  
  useEffect(() => {
    if (accessLevel === 3 || accessLevel === 4) {
      setProviderAccess(true);
    } else {
      setProviderAccess(false);
    }
  }, [accessLevel]);

  const [isAboutHovered, setAboutHovered] = useState(false);
  const [isScenarioHovered, setScenarioHovered] = useState(false);

  const handleAboutHover = () => {
    setAboutHovered(true);
  };

  const handleAboutLeave = () => {
    setAboutHovered(false);
  };

  const handleScenarioHover = () => {
    setScenarioHovered(true);
  };

  const handleScenarioLeave = () => {
    setScenarioHovered(false);
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: '0px',
        width: '100%',
        height: 80,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#20233A',
        fontSize: 15,
      }}
    >
      <div
        style={{
          height: 80,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#20233A',
          fontSize: 15,
        }}
      >
        <img src={Logo} alt='Logo Image' style={{ width: '90px', padding: '20px' }} />
        <h1 style={{ color: '#FFFFFF', paddingLeft: '30px' }}>RealEyes</h1>
      </div>
      <div style={{ paddingRight: '40px' }}>
        <Link to='/' style={{ padding: linkPaddingPixels, color: '#FFFFFF', textDecoration: 'none' }}>
          Home
        </Link>
        <div
          style={{ position: 'relative', display: 'inline-block' }}
          onMouseEnter={handleAboutHover}
          onMouseLeave={handleAboutLeave}
        >
          <div style={{ padding: linkPaddingPixels, color: '#FFFFFF', textDecoration: 'none' }}>About</div>
          {isAboutHovered && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                backgroundColor: '#20233A',
                minWidth: '200px',
                zIndex: '1',
                borderRadius: 2,
              }}
            >
              <Link
                to='/LearningObjectives'
                style={{ display: 'block', padding: '10px', color: '#FFFFFF', textDecoration: 'none' }}
              >
                Learning Objectives
              </Link>
              <Link
                to='/SessionStructure'
                style={{ display: 'block', padding: '10px', color: '#FFFFFF', textDecoration: 'none' }}
              >
                Session Structure
              </Link>
              <Link
                to='/IntroductionToReflection'
                style={{ display: 'block', padding: '10px', color: '#FFFFFF', textDecoration: 'none' }}
              >
                Introduction to Reflection
              </Link>
            </div>
          )}
        </div>
        <div
          style={{ position: 'relative', display: 'inline-block' }}
          onMouseEnter={handleScenarioHover}
          onMouseLeave={handleScenarioLeave}
        >
          <div style={{ padding: linkPaddingPixels, color: '#FFFFFF', textDecoration: 'none' }}>Scenarios</div>
          {isScenarioHovered && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                backgroundColor: '#20233A',
                minWidth: '200px',
                zIndex: '1',
                borderRadius: 2,
              }}
            >
              <Link
                to='/AggressivePatient'
                style={{ display: 'block', padding: '10px', color: '#FFFFFF', textDecoration: 'none' }}
              >
                Aggressive Patient
              </Link>
              <Link
                to='/ClinicalError'
                style={{ display: 'block', padding: '10px', color: '#FFFFFF', textDecoration: 'none' }}
              >
                Clinical Error
              </Link>
              <Link
                to='/METCall'
                style={{ display: 'block', padding: '10px', color: '#FFFFFF', textDecoration: 'none' }}
              >
                MET Call
              </Link>
              <Link
                to='/Stroke'
                style={{ display: 'block', padding: '10px', color: '#FFFFFF', textDecoration: 'none' }}
              >
                Stroke
              </Link>
            </div>
          )}
        </div>
        <Link to='/NextSteps' style={{ padding: linkPaddingPixels, color: '#FFFFFF', textDecoration: 'none' }}>
          Next Steps
        </Link>
        <Link to='/ProjectReach' style={{ padding: linkPaddingPixels, color: '#FFFFFF', textDecoration: 'none' }}>
          Project Reach
        </Link>
        <Link to='/Contact' style={{ padding: linkPaddingPixels, color: '#FFFFFF', textDecoration: 'none' }}>
          Contact
        </Link>
        {providerAccess && (
          <Link to='/InformationForProviders' style={{ padding: linkPaddingPixels, color: '#FFFFFF', textDecoration: 'none' }}>
            Information for Providers
          </Link>
        )}
        <button
          onClick={handleLogout}
          style={{ padding: linkPaddingPixels, color: '#333', backgroundColor: '#FFFFFF', borderColor: '#CCC', borderRadius: 5, textDecoration: 'none' }}
        >
          Log out
        </button>
      </div>
    </div>
  );
};

export default Navbar;
