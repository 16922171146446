import React from 'react';
import Table from 'react-bootstrap/Table';
import ReactPlayer from "react-player";

import gibbsExample from './../images/Gibbs reflective cycle example.pdf'
import gibbsReflectiveCycle from './../images/Gibbs reflective cycle.pdf'

const padding = '10%'

const IntroductionToReflection = () => {
  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
      <br />
      <h1>Introduction to Reflection</h1>
      <div>
        <div>
          <ReactPlayer
            url='https://vimeo.com/829077427/fd092263d1'
            controls='true'
            width='100%'
          />
        </div>
        <br />
        <p>During the facilitated discussion, we will ask you to reflect on any discussion points that have arisen during the scenario. We have found that Gibbs' Reflective Cycle is a very useful framework to structure this reflection. The image below summarises the questions you should ask yourself when working through each domain:</p>
        <div style={{ display: 'flex', paddingLeft: '30px', paddingRight: '30px', justifyContent: 'center' }}>
          <br />
          <object data={gibbsReflectiveCycle} type="application/pdf" width="75%" height='800px'>
        </object>
          <br />
        </div>
        <p>Furthermore, Gibbs' Reflective Cycle is a useful tool when reflecting on your own clinical situations and it is something that we encourage you to consider implementing into your structured reflections in future.</p>
        <p>This is an example of a full reflective cycle on a clinical encounter. Here we have reflected upon a real clinical situation where we had a chest x-ray demonstrating a possible malignancy that requires further investigation:</p>
        <div style={{ display: 'flex', paddingLeft: '30px', paddingRight: '30px', justifyContent: 'center' }}>
          <br />
          <object data={gibbsExample} type="application/pdf" width="75%" height='800px'>
        </object>
          <br />
        </div>
        <br />
        <p>Some further examples of good reflections can be found here:</p>
        <ul>
          <li><a href='https://www.ed.ac.uk/reflection/reflectors-toolkit/reflecting-on-experience/gibbs-reflective-cycle'>https://www.ed.ac.uk/reflection/reflectors-toolkit/reflecting-on-experience/gibbs-reflective-cycle</a></li>
          <li><a href='https://www.mmu.ac.uk/media/mmuacuk/content/documents/careers/Applying-the-Gibbs-Model-2018.pdf'>https://www.mmu.ac.uk/media/mmuacuk/content/documents/careers/Applying-the-Gibbs-Model-2018.pdf</a></li>
        </ul>
      </div>
    </div>
  );
};

export default IntroductionToReflection;