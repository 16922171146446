import React from 'react';
import Table from 'react-bootstrap/Table';
import ReactPlayer from "react-player";

import gibbsExample from './../images/Gibbs reflective cycle example.pdf'
import gibbsReflectiveCycle from './../images/Gibbs reflective cycle.pdf'

const padding = '10%'

const LearningObjectives = () => {
  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
      <br />
      <h1>Learning Objectives</h1>
      <div>
        <p>
          <i>Outcomes for graduates</i> sets out what newly qualified doctors, from all medical schools who award UK primary medical qualifications, must know and be able to do. <i>Outcomes for graduates</i> is:
        </p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <ul>
          <li style={{ textAlign: 'left' }}>A guide for students on what they need to learn during their time at medical school</li>
          <li style={{ textAlign: 'left' }}>A basis for medical schools to develop their curricula and programmes of learning</li>
          <li style={{ textAlign: 'left' }}>A blueprint or plan for assessments at medical schools</li>
          <li style={{ textAlign: 'left' }}>A framework we use to regulate medical schools</li>
          <li style={{ textAlign: 'left' }}>A summary of what newly qualified doctors will know and be able to do for those designing postgraduate training</li>
        </ul>
      </div>
      <div>
        <p>
          The document structures its outcomes into three primary headings. These are further subdivided into more specific realms:
        </p>
        <div style={{ display: 'flex', alignContent: 'center', justifyItems: 'center', paddingLeft: '60px', paddingRight: '60px' }}>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Professional values and behaviours</th>
                <th>Professional skills</th>
                <th>Professional knowledge</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{color: '#EE7C30'}}>Professional and ethical responsibilities</td>
                <td>Communication and interpersonal skills</td>
                <td>The health service and healthcare systems in the four countries</td>
              </tr>
              <tr>
                <td>Legal responsibilities</td>
                <td>Diagnosis and medical management</td>
                <td>Applying biomedical scientific principles</td>
              </tr>
              <tr>
                <td>Patient safety and quality improvement</td>
                <td>Prescribing medications safely</td>
                <td>Applying psychological principles</td>
              </tr>
              <tr>
                <td>Dealing with complexity and uncertainty</td>
                <td>Using information effectively and safely</td>
                <td>Applying social science principles</td>
              </tr>
              <tr>
                <td>Safeguarding vulnerable patients</td>
                <td></td>
                <td>Health promotion and illness prevention</td>
              </tr>
              <tr>
                <td>Leadership and team working</td>
                <td></td>
                <td>Clinical research and scholarship</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <p>
        We understand that, at medical school, the primary focus is on meeting the skills in professional skills and professional knowledge. However, sometimes this comes at the cost of learning professional values and behaviours.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <object data="https://www.gmc-uk.org/-/media/documents/outcomes-for-graduates-2020_pdf-84622587.pdf" type="application/pdf" width="75%" height='800px'>
        </object>
      </div>

      <div>
        <br />
        <p>If you read the <i>Professional and Ethical Responsibilities</i> section in <i>Outcomes for Graduates</i>, you might notice these common themes:</p>
        <ul>
          <li style={{ textAlign: 'left' }}>Professional development</li>
          <li style={{ textAlign: 'left' }}>Time management</li>
          <li style={{ textAlign: 'left' }}>Reflection</li>
          <li style={{ textAlign: 'left' }}>Duty of candour</li>
          <li style={{ textAlign: 'left' }}>Honesty</li>
          <li style={{ textAlign: 'left' }}>Raising concerns</li>
          <li style={{ textAlign: 'left' }}>Integrity</li>
          <li style={{ textAlign: 'left' }}>Meeting fundamental needs of patients</li>
          <li style={{ textAlign: 'left' }}>Risks posed to patients by doctor<span>&#39;</span>s health</li>
          <li style={{ textAlign: 'left' }}>Impact of personal values, beliefs and biases</li>
          <li style={{ textAlign: 'left' }}>Applying ethical reasoning to new situations</li>
          <li style={{ textAlign: 'left' }}>Person-centred care and involvement of relatives, carers or other advocates</li>
          <li style={{ textAlign: 'left' }}>Recognising personal limits</li>
          <li style={{ textAlign: 'left' }}>Maintaining dignity</li>
          <li style={{ textAlign: 'left' }}>Decision making, capacity and informed consent</li>
        </ul>
        <p>These will form the learning objectives for RealEyes and provides the basis for what we are going to teach you.</p>
        <p>We aim to address the 'reflection' and 'professional development' learning objectives continuously throughout the course.</p>
        <p>All of the remaining learning objectives will be specifically met at least once in the four scenarios.</p>
      </div>
      <div>
        </div>
    </div>
  );
};

export default LearningObjectives;