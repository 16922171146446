import React from 'react';
import ReactPlayer from "react-player";
import Table from 'react-bootstrap/Table';
import logo from './../images/realeyesLogo.png';

const padding = '10%'

const NextSteps = () => {
  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
      <br />
      <h1>Next steps</h1>
      <br />
      <p>
If you would like to watch any of these scenarios again at home, then take a look at making your own VR headset for your phone <a href='https://arvr.google.com/intl/en_uk/cardboard/'>here</a>.</p>
      
      </div>
    );
};

export default NextSteps;