import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const padding = '10%';

const Admin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [accessLevel, setAccessLevel] = useState(0);

  const handleAddUser = async (event) => {
    event.preventDefault();
    try {
      // Create a user with email and password
      await firebase.auth().createUserWithEmailAndPassword(email, password);

      // Access the Firestore and add the user with email and access level
      const db = firebase.firestore();
      await db.collection('Users').doc(email).set({
        email: email,
        accessLevel: accessLevel,
      });

      // Reset the form fields after adding the user
      setEmail('');
      setPassword('');
      setAccessLevel(0);

      // Provide feedback to the user about the successful addition
      alert('User added successfully!');
    } catch (error) {
      console.error('Error adding user:', error.message);
      // Provide feedback to the user about the error occurred while adding the user
      alert('Error adding user. Please check the console for details.');
    }
  };

  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
        <br />
      <form onSubmit={handleAddUser} style={{ textAlign: 'center' }}>
        <input
          type='email'
          placeholder='Email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: '15px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '300px' }}
          required
        />
        <br />
        <input
          type='password'
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginBottom: '15px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '300px' }}
          required
        />
        <br />
        <label htmlFor='accessLevel'>Access Level:</label>
        <select
          id='accessLevel'
          value={accessLevel}
          onChange={(e) => setAccessLevel(Number(e.target.value))}
          style={{ marginBottom: '15px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '300px' }}
        >
          <option value={0}>Demo</option>
          <option value={1}>Student (pre-course)</option>
          <option value={2}>Student (post-course)</option>
          <option value={3}>Provider</option>
          <option value={4}>Admin</option>
        </select>
        <br />
        <button
          type='submit'
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            color: '#FFFFFF',
            backgroundColor: '#20233A',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          }}
        >
          Add User
        </button>
      </form>
    </div>
  );
};

export default Admin;
