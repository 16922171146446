import React from 'react'

const padding = '10%'

const Contact = () => {
  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
      <br />
      <h1>Feedback and Contacts</h1>
      <p>RealEyes is a work in progress and we gladly welcome any feedback you might have.</p>
      <p>If you have already completed the course, please feel free to leave any comments <a href='https://docs.google.com/forms/d/e/1FAIpQLSfF35dlP110kXTQ4tEAIU8pwfXqQEuRS2uQlEheCdXEUJ6mVg/viewform'>here</a> and we will see whether we can implement any suggestions to improve the course.</p>
      <p>If you have not completed the course, or have more general suggestions, the please fill in <a href='https://docs.google.com/forms/d/e/1FAIpQLSeQIwYPIo9P41hw4vBXXcXOC97Cby6bu_ANyg12D6FbYCjd7A/viewform'>this form</a>.</p>
      <p>We would love to deliver RealEyes on a larger scale and, if you are interested in delivering this course in your local centre, please contact <a href='mailto:matthew.whallett@doctors.org.uk'>Dr Matthew Whallett</a> to discuss how this can be organised!</p>
      <br />
      <h4>Project members and contact details</h4>
      <br />
      <h5>Project leads</h5>
      <p>Dr Matthew Whallett</p>
      <p style={{paddingLeft: '8px', fontSize: 12}}>Email: <a href='mailto:matthew.whallett@doctors.org.uk'>matthew.whallett@doctors.org.uk</a></p>
      <p style={{paddingLeft: '8px', fontSize: 12}}>Website: <a target="_blank" href='http://www.matthewwhallett.co.uk'>matthewwhallett.co.uk</a></p>
      <p>Dr Sindoora Jayaprakash</p>
      <p style={{paddingLeft: '8px', fontSize: 12}}>Email: <a href='mailto:sindoora.jayaprakash1@nhs.net'>sindoora.jayaprakash1@nhs.net</a></p>
      <h5>Supervisers</h5>
      <p>Mr Usman Ahmed</p>
      <p>Professor Atiq Rehman</p>
      <p>Mr Alexander Crichton</p>
      <p>Mr Joshua Whittaker</p>
      <h5>360° VR Production Team</h5>
      <p>Mr Ed Shovelton</p>
      <p style={{paddingLeft: '8px', fontSize: 12}}>Email: <a href='mailto:ed.shovelton@nhs.net'>ed.shovelton@nhs.net</a></p>
      <p>Mr Neil Parmar</p>
      <p>Miss Charlie Pountney</p>
      <h5>Website design</h5>
      <p>Dr Matthew Whallett</p>
    </div>
  )
}

export default Contact