import React from 'react';
import ReactPlayer from "react-player";
import Table from 'react-bootstrap/Table';
import logo from './../images/realeyesLogo.png';

import Integrity from './../miniPresentations/Integrity.pdf'
import DutyOfCandour from './../miniPresentations/Duty of candour.pdf'
import TimeManagement from './../miniPresentations/Time Management.pdf'
import Demonstration from './../miniPresentations/Demonstration.pdf'

const padding = '10%'

const ClinicalError = ({demo = false}) => {
  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
      <br />
      <h1>Clinical Error Scenario</h1>
      <p>During this scenario, we will address the following learning objectives:</p>
      <ul>
        <li>Duty of Candour</li>
        <li>Integrity</li>
        <li>Time management</li>
      </ul>
      <br />
      <h4>Viewpoint 1 - Doctor</h4>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactPlayer url={demo ? 'https://vimeo.com/803030354/7084a19d53' : 'https://vimeo.com/846534536/c0e9581da0'} controls='true'/>
      </div>
      <h4>Viewpoint 2 - Nurse</h4>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactPlayer url={demo ? 'https://vimeo.com/803030354/7084a19d53' : 'https://vimeo.com/846534605/ab3c0bb25f'} controls='true'/>
      </div>
      <br />
      <h2>Mini-presentations</h2>
      <br />
      <ul>
        <li><a href={demo ? Demonstration : DutyOfCandour} target='self'>Duty of Candour</a></li>
        <li><a href={demo ? Demonstration : Integrity} target='self'>Integrity</a></li>
        <li><a href={demo ? Demonstration : TimeManagement} target='self'>Time management</a></li>
      </ul>
      <br />
      <h2>Acting credits</h2>
      <br />
      <li>Manpreet, FY1 doctor (viewpoint 1) - Manpreet Badh</li>
      <li>Paul Stevens, consultant - Andy Checketts</li>
      <li>Rhys, nurse (viewpoint 2) - Giles Blacker-Morgan</li>
      <li>Tash, nursing assistant - Natasha Semple</li>
      <li>James Ringer, patient - Jim Yarwood</li>
      <li>Chloe Ringer, relative - Nina Warwick</li>
      <li>Bill, patient - Ian 'Wiggy' Underwood</li>
      <li>Unnamed doctor - Frederick Smith</li>
    </div>
  );
};

export default ClinicalError;