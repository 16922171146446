import React from 'react';
import Table from 'react-bootstrap/Table';
import ReactPlayer from "react-player";

import gibbsExample from './../images/Gibbs reflective cycle example.pdf'
import gibbsReflectiveCycle from './../images/Gibbs reflective cycle.pdf'

const padding = '10%'

const SessionStructure = () => {
  return (
    <div style={{ width: '100%', paddingTop: '80px', paddingLeft: padding, paddingRight: padding, paddingBottom: '80px' }}>
      <br />
      <h1>Session Structure</h1>
      <div>
      <div>
          <ReactPlayer
            url='https://vimeo.com/838308168/86cf08855f'
            controls='true'
            width='100%'
          />
        </div>
        <br />
        <p>Each face-to-face session follows the same skeleton structure. You will complete five sessions and each session will utilise a different scenario.</p>
        <p>The skeleton structure is as follows:</p>
        <ul>
          <li>Explain session structure</li>
          <li>Personal introductions</li>
          <li>Use virtual reality headsets so each individual can watch the 360 degree footage from the point of view of the <i>main character</i> in each scenario. Everyone watches the same footage at the same time</li>
          <li>Watch virtual reality video scenario</li>
          <li>2-minute wind-down aimed to allow internal processing of scenario and initial personal reflection</li>
          <li>Discuss what happened in the scenario</li>
          <li>Students will then select a trigger point from the scenario to discuss further</li>
          <li>Use Gibbs reflective cycle to reflect on this trigger point, whilst being guided by the facilitator if necessary:</li>
          <ul>
            <li>What were you thinking and feeling at this point?</li>
            <li>Were there good areas to be discussed about this point? </li>
            <li>Were there bad areas to be discussed about this point? </li>
            <li>Why were these areas good or bad?</li>
            <li>What else could have been done at this point?</li>
            <li>If this arose again, what would you do differently</li>
          </ul>
          <li>Students will then discuss two or three further trigger points, guided by the facilitator if necessary</li>
          <li>Final comments</li>
          <li>Facilitator wrap-up</li>
        </ul>
        <p>Furthermore, you will be given the opportunity to share and discuss personal anecdotes that you feel are relevant to the discussion.</p>
      </div>
    </div>
  );
};

export default SessionStructure;